import { Component, Input, OnInit, OnDestroy } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Subscription } from "rxjs";
import dayjs from "dayjs";

import { Partner } from "../../../shared/models/partner";
import { PartnerCampaign } from "../../../shared/models/partnerCampaign";
import { PartnerCampaignDetails } from "../../../shared/models/partnerCampaignDetail";
import { PartnerCampaignFacebookPost } from "../../../shared/models/partnerCampaignFacebookPost";
import { PartnerCampaignGoogleAdPost } from "../../../shared/models/partnerCampaignGoogleAdPost";
import { PartnerCampaignPost } from "../../../shared/models/partnerCampaignPost";
import { PostLog } from "../../../shared/models/postLog";
import { PartnerCampaignService } from "../../../shared/services/api/partner-campaign.service";
import { PartnerCampaignPromoteAdDialogData } from "../../partner-campaign-promote-ad-dialog/partner-campaign-promote-ad-dialog-data";
import { PartnerCampaignPromoteAdDialogComponent } from "../../partner-campaign-promote-ad-dialog/partner-campaign-promote-ad-dialog.component";
import { CampaignPostContentType } from "../../../shared/enums/campaignPost.enums";
import {
  AdActionType,
  PaymentType,
} from "../../../shared/enums/campaign.enums";
import { PartnerDialogService } from "../../partner-dialog.service";

enum AfterConnectAction {
  ScheduleFacebookPost,
  SchedulePaidAd,
  ScheduleAd,
}

@Component({
  selector: "app-partner-campaign-download-category-post",
  templateUrl: "./partner-campaign-download-category-post.component.html",
  styleUrl: "./partner-campaign-download-category-post.component.scss",
})
export class PartnerCampaignDownloadCategoryPostComponent
  implements OnInit, OnDestroy
{
  @Input({ required: true }) public campaign!: PartnerCampaign;
  @Input({ required: true }) public campaignDetails!: PartnerCampaignDetails;
  @Input({ required: true }) public post!: PartnerCampaignPost | PostLog;
  @Input({ required: true }) public partner!: Partner;

  protected validForm = true;
  protected facebookPost?: PartnerCampaignFacebookPost;
  protected googleAdPost?: PartnerCampaignGoogleAdPost;
  protected isCreateAdCheckboxDisabled = false;

  private readonly subscription = new Subscription();
  private createAd = false;

  constructor(
    private readonly dialog: MatDialog,
    private readonly partnerCampaignService: PartnerCampaignService,
    private readonly partnerDialogService: PartnerDialogService,
  ) {}

  public ngOnInit(): void {
    if (this.post instanceof PartnerCampaignFacebookPost) {
      this.facebookPost = this.post as PartnerCampaignFacebookPost;
      this.isCreateAdCheckboxDisabled =
        this.campaign.adActionType === AdActionType.Boost &&
        (this.facebookPost.mediaType === CampaignPostContentType.Gif ||
          this.facebookPost.mediaType === CampaignPostContentType.Image360);
    } else if (this.post instanceof PartnerCampaignGoogleAdPost) {
      this.googleAdPost = this.post as PartnerCampaignGoogleAdPost;
    }
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  protected async showAdMoreBudgetDialog(): Promise<void> {
    if (this.isGoogleAdMoreBudgetButtonDisabled || !this.campaign.partner) {
      return;
    }

    if (await this.isVatNumberMissing()) {
      return;
    }

    if (await this.isShortUrlMissing()) {
      return;
    }

    if (this.facebookPost) {
      if (!this.partner.isConnectedToFB) {
        this.showFBConnectionModal();
        return;
      }

      /*
      // We are not showing a warning, instead we will set the postToInstagram to false
      if (
        !this.partner.isConnectedToInstagram &&
        this.facebookPost.postToInstagram &&
        this.facebookPost.canBePostedToInstagram
      ) {
        this.showFBConnectionModal();
        return;
      }
      */

      if (!this.partner.isAuthorizedFBAds) {
        this.partnerDialogService.showPartnerAuthorizeFacebookAds({
          partner: this.partner,
        });
        return;
      }

      this.showScheduleAdDialog();
    } else if (this.googleAdPost) {
      this.showScheduleAdDialog();
    }
  }

  protected async showScheduleFacebookPostDialog(): Promise<void> {
    if (!this.campaign.partner) {
      return;
    }

    if (await this.isVatNumberMissing()) {
      return;
    }

    if (await this.isShortUrlMissing()) {
      return;
    }

    if (!this.partner.isConnectedToFB) {
      return await this.showFBConnectionModal();
    }

    if (this.createAd) {
      if (!this.partner.isAuthorizedFBAds) {
        await this.partnerDialogService.showPartnerAuthorizeFacebookAds({
          partner: this.partner,
        });
        return;
      }
      await this.showScheduleAdDialog();
    } else {
      await this.partnerDialogService.showPartnerCampaignPublishPost({
        campaign: this.campaign,
        partner: this.partner,
        post: this.post as PartnerCampaignFacebookPost,
      });
    }
  }

  protected async showScheduleAdDialog(): Promise<void> {
    if (!this.campaign.partner) {
      return;
    }

    if (this.campaign.isPartnerPendingToDefineACustomCampaignBudget) {
      const result = await this.partnerDialogService.showConfirmBudgetDialog({
        campaign: this.campaign,
      });

      if (!result) {
        return;
      }
    }

    if (await this.isVatNumberMissing()) {
      return;
    }

    if (await this.isShortUrlMissing()) {
      return;
    }

    let postToFacebook = false;
    let postToInstagram = false;
    if (this.post.isFacebookPost) {
      const facebookPost = this.post as PartnerCampaignFacebookPost;
      postToFacebook = facebookPost.postToFacebook;
      postToInstagram =
        facebookPost.canBePostedToInstagram &&
        this.partner.isConnectedToInstagram
          ? facebookPost.postToInstagram
          : false;
    }

    this.dialog.open(PartnerCampaignPromoteAdDialogComponent, {
      data: new PartnerCampaignPromoteAdDialogData(
        this.campaign,
        this.post,
        dayjs().add(1, "hours").toDate(),
        this.partner,
        true,
        postToFacebook,
        postToInstagram,
      ),
      disableClose: true,
    });
  }

  private async showMissingShortUrlModal(
    afterConnectAction: AfterConnectAction,
  ): Promise<void> {
    const campaignPartner =
      await this.partnerDialogService.showInvalidCampaignShortUrl({
        campaign: this.campaign,
      });

    if (campaignPartner) {
      this.campaign.partner.shortUrl = campaignPartner.shortUrl;
      switch (afterConnectAction) {
        case AfterConnectAction.ScheduleFacebookPost:
          this.showScheduleFacebookPostDialog();
          break;
        case AfterConnectAction.SchedulePaidAd:
          this.showScheduleFacebookPostDialog();
          break;
        case AfterConnectAction.ScheduleAd:
          this.showScheduleAdDialog();
          break;
      }
    }
  }

  private async showFBConnectionModal(): Promise<void> {
    await this.partnerDialogService.showFacebookNotConnected({
      partner: this.partner,
    });
  }

  public get tooManyAds(): boolean {
    if (!this.campaign) {
      return true;
    }
    return !this.campaign.canPromoteMoreAds;
  }

  public get adsCreatedString(): string {
    if (!this.campaign) {
      return "";
    }
    return `${this.campaign.numberOfAdsCreated} of ${this.campaign.maximumNumberOfAds}`;
  }

  protected isFacebookPostButtonDisabled(): boolean {
    return (
      !this.campaign || !this.facebookPost?.text || this.campaign?.hasFinished
    );
  }

  public get isGoogleAdPostButtonDisabled(): boolean {
    return (
      !this.campaign ||
      !this.partner ||
      !this.partner.areGoogleAdsAuthorized ||
      !this.googleAdPost?.headlineOne ||
      !this.validForm ||
      this.campaign?.hasFinished
    );
  }

  public get isGoogleAdMoreBudgetButtonDisabled(): boolean {
    return (
      !this.campaign ||
      !this.partner ||
      !this.partner.areGoogleAdsAuthorized ||
      !this.validForm ||
      this.campaign?.hasFinished
    );
  }

  private async isShortUrlMissing(): Promise<boolean> {
    if (
      this.campaign.hasLandingPageRetailerOwnLink &&
      this.campaign.partner.isShortUrlMissing
    ) {
      await this.showMissingShortUrlModal(
        AfterConnectAction.ScheduleFacebookPost,
      );
      return true;
    }
    return false;
  }

  private async isVatNumberMissing(): Promise<boolean> {
    if (
      (this.createAd || !!this.googleAdPost) &&
      this.campaign.paymentType !== PaymentType.Brand &&
      !this.partner.vatNumber
    ) {
      const response = await this.partnerDialogService.showVatInfoDialog({
        partner: this.partner,
      });

      if (!(response instanceof Partner) || !response.vatNumber) {
        return true;
      }
      this.partner.vatNumber = response.vatNumber;
    }
    return false;
  }
}

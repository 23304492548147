import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { PostPlatform } from "../../../shared/enums/campaignPost.enums";
import { PartnerFacebookPostLog } from "../../../shared/models/partnerFacebookPostLog";
import { PartnerGoogleAdPostLog } from "../../../shared/models/partnerGoogleAdPostLog";

import { PartnerService } from "../../../shared/services/api/partner.service";
import { HttpClient } from "../../../shared/services/http-client";
import { CreateFacebookAdFromPostData } from "../../../shared/services/parameters/create-facebook-ad-from-post-data";
import { CreateFacebookAdFromPostLogData } from "../../../shared/services/parameters/create-facebook-ad-from-post-log-data";
import { CreateGoogleAdFromPostData } from "../../../shared/services/parameters/create-google-ad-from-post-data";
import { CreatePaidAdData } from "../../../shared/services/parameters/create-paid-ad-data";
import { assert } from "../../../shared/utils/assert";

@Injectable()
export class PartnerCampaignAdService {
  constructor(
    private http: HttpClient,
    public partnerService: PartnerService,
  ) {}

  private getCurrentPartnerId(): number {
    return this.partnerService.currentPartnerId;
  }

  public deleteCampaignPostLogAd(postId: number): Observable<any> {
    const partnerId = this.getCurrentPartnerId();
    return this.http
      .del(`partner/${partnerId}/post_log/${postId}/ad`)
      .pipe(map(({ body }) => body));
  }

  public getStripePaymentIntent(
    campaignId: number,
    budgetAmountInCents: number,
  ): Observable<string> {
    const partnerId = this.getCurrentPartnerId();
    return this.http
      .post(
        `partner/${partnerId}/campaign/${campaignId}/get_stripe_payment_intent`,
        { budgetAmountInCents: budgetAmountInCents },
      )
      .pipe(
        map(({ body }) => {
          assert(body.token, "Stripe payment intent token is missing");
          return body.token;
        }),
      );
  }

  public createPaidAdFromPost(
    campaignId: number,
    createPaidAdFromPostData: CreatePaidAdData,
    clientSecret: string,
    chargeId: string,
  ): Observable<PartnerFacebookPostLog | PartnerGoogleAdPostLog> {
    const partnerId = this.getCurrentPartnerId();
    return this.http
      .post(`partner/${partnerId}/campaign/${campaignId}/create_paid_ad`, {
        clientSecret: clientSecret,
        chargeId: chargeId,
        ...createPaidAdFromPostData,
      })
      .pipe(
        map(({ body }) => {
          if (body.platform === PostPlatform.Facebook) {
            return new PartnerFacebookPostLog(body);
          }

          return new PartnerGoogleAdPostLog(body);
        }),
      );
  }

  public createGoogleAd(
    campaignId: number,
    data: CreateGoogleAdFromPostData,
  ): Observable<PartnerGoogleAdPostLog> {
    const partnerId = this.getCurrentPartnerId();
    return this.http
      .post(
        `partner/${partnerId}/campaign/${campaignId}/google_ad`,
        JSON.stringify(data),
      )
      .pipe(map(({ body }) => new PartnerGoogleAdPostLog(body)));
  }

  public createFacebookAd(
    campaignId: number,
    data: CreateFacebookAdFromPostData,
  ): Observable<PartnerFacebookPostLog> {
    const partnerId = this.getCurrentPartnerId();
    return this.http
      .post(
        `partner/${partnerId}/campaign/${campaignId}/facebook_post_and_or_ad`,
        JSON.stringify(data),
      )
      .pipe(map(({ body }) => new PartnerFacebookPostLog(body)));
  }

  public createFacebookAdFromPostLog(
    campaignId: number,
    data: CreateFacebookAdFromPostLogData,
  ): Observable<PartnerFacebookPostLog> {
    const partnerId = this.getCurrentPartnerId();
    return this.http
      .post(
        `partner/${partnerId}/campaign/${campaignId}/facebook_ad_from_existing_post_log`,
        JSON.stringify(data),
      )
      .pipe(map(({ body }) => new PartnerFacebookPostLog(body)));
  }
}

<div *ngIf="!paymentSuccess">
  <div class="summary">
    <img class="summary-pay-icon" src="/assets/images/pay_icon.png" />
    <div class="summary-title">
      {{ "partner.promoteAd.paymentTitle" | translate }}
    </div>
    <div class="summary-subtitle">
      {{
        "partner.promoteAd.paymentSubtitle"
          | translate
            : {
                budget: budget,
                days: adDuration,
                currency: campaign.currencySymbol
              }
      }}
    </div>
    <div class="summary-totals">
      <div class="summary-line-item summary-line-item--border-bottom">
        <div>{{ "partner.promoteAd.subtotal" | translate }}</div>
        <div>{{ budget | number: ".2-2" }} {{ campaign.currencySymbol }}</div>
      </div>
      <div class="summary-line-item">
        <div>{{ "partner.promoteAd.managementFee" | translate }}</div>
        <div>
          {{ managementFee | number: ".2-2" }} {{ campaign.currencySymbol }}
        </div>
      </div>
      <div class="summary-line-item">
        <div>{{ "partner.promoteAd.net" | translate }}</div>
        <div>
          {{ netAmount | number: ".2-2" }} {{ campaign.currencySymbol }}
        </div>
      </div>
      <div class="summary-line-item summary-line-item--border-bottom">
        <div>{{ "partner.promoteAd.tax" | translate }}</div>
        <div>
          {{ taxAmount | number: ".2-2" }} {{ campaign.currencySymbol }}
        </div>
      </div>
      <div class="summary-line-item summary-line-item--orange">
        <div>{{ "partner.promoteAd.total" | translate }}</div>
        <div>
          {{ totalCost | number: ".2-2" }} {{ campaign.currencySymbol }}
        </div>
      </div>
    </div>
  </div>
  @if (elementsOptions) {
    <form
      novalidate
      (ngSubmit)="processPayment()"
      [formGroup]="stripeFormGroup"
    >
      @if (showPaymentElement) {
        <div class="stripe-payment">
          <ngx-stripe-elements
            [stripe]="stripeService"
            [elementsOptions]="elementsOptions"
          >
            <ngx-stripe-payment />
          </ngx-stripe-elements>
        </div>
      }
      <div *ngIf="processingPayment" class="progress-wrap">
        <mat-progress-bar
          [color]="'accent'"
          style="height: 20px"
          mode="indeterminate"
        >
        </mat-progress-bar>
      </div>
      <div *ngIf="!processingPayment" class="button-wrap">
        <button
          type="submit"
          [disabled]="stripeFormGroup.invalid"
          class="button-orange pay-button"
        >
          {{ "partner.promoteAd.pay" | translate }}
        </button>
        <button (click)="goBack()" class="button-transparent cancel-button">
          {{ "shared.cancel" | translate }}
        </button>
      </div>
    </form>
  } @else {
    <div class="stripe-loading">
      <mat-progress-bar
        [color]="'accent'"
        style="height: 20px"
        mode="indeterminate"
      >
      </mat-progress-bar>
    </div>
  }
</div>

import dayjs from "dayjs";

import { PartnerCampaignFacebookPost } from "../../models/partnerCampaignFacebookPost";

export class CreatePaidFacebookAdFromPostData {
  public postId: number;
  public adDuration: number;
  public addressId: number;
  public scheduledPublishDate: number;
  public isDarkPost: boolean;
  public radius: number;
  public text: string;
  public postToFacebook: boolean;
  public postToInstagram: boolean;
  constructor(
    post: PartnerCampaignFacebookPost,
    adDuration: number,
    addressId: number,
    scheduledPublishDate: Date,
    isDarkPost: boolean,
    radius: number,
    postToFacebook: boolean,
    postToInstagram: boolean,
  ) {
    this.postId = post.id;
    this.text = post.text;
    this.adDuration = adDuration;
    this.addressId = addressId;
    this.scheduledPublishDate = dayjs(scheduledPublishDate).unix();
    this.isDarkPost = isDarkPost;
    this.radius = radius;
    this.postToFacebook = postToFacebook;
    this.postToInstagram = postToInstagram;
  }
}

import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { StripeElementsOptionsMode } from "@stripe/stripe-js/dist/stripe-js/elements-group";
import { StripeElementsDirective, StripeService } from "ngx-stripe";
import { lastValueFrom } from "rxjs";
import { environment as env } from "../../../../environments/environment";
import { Address } from "../../../shared/models/address";
import { PartnerCampaign } from "../../../shared/models/partnerCampaign";
import { PartnerCampaignFacebookPost } from "../../../shared/models/partnerCampaignFacebookPost";
import { PartnerCampaignGoogleAdPost } from "../../../shared/models/partnerCampaignGoogleAdPost";
import { PartnerCampaignPost } from "../../../shared/models/partnerCampaignPost";
import { PartnerFacebookPostLog } from "../../../shared/models/partnerFacebookPostLog";
import { PartnerGoogleAdPostLog } from "../../../shared/models/partnerGoogleAdPostLog";
import { LanguageService } from "../../../shared/services/language.service";
import { CreatePaidAdData } from "../../../shared/services/parameters/create-paid-ad-data";
import { CreatePaidFacebookAdFromPostData } from "../../../shared/services/parameters/create-paid-facebook-ad-from-post-data";
import { CreatePaidFacebookAdFromPostLogData } from "../../../shared/services/parameters/create-paid-facebook-ad-from-post-log-data";
import { CreatePaidGoogleAdFromPostData } from "../../../shared/services/parameters/create-paid-google-ad-from-post-data";
import { PartnerCampaignAdService } from "../../shared/services/partner-campaign-ad.service";
import { PromoteAdPaymentErrorEvent } from "./partner-campaign-promote-ad-payment-error-event";

@Component({
  selector: "app-partner-campaign-promote-ad-payment",
  templateUrl: "./partner-campaign-promote-ad-payment.component.html",
  styleUrl: "./partner-campaign-promote-ad-payment.component.scss",
})
export class PartnerCampaignPromoteAdPaymentComponent implements OnInit {
  @Input({ required: true }) public address!: Address;
  @Input({ required: true }) public adDuration!: number;
  @Input({ required: true }) public budget!: number;
  @Input({ required: true }) public campaign!: PartnerCampaign;
  @Input({ required: true }) public isDarkPost!: boolean;
  @Input({ required: true }) public postToFacebook!: boolean;
  @Input({ required: true }) public postToInstagram!: boolean;
  @Input({ required: true }) public radius!: number;
  @Input({ required: true }) public scheduledPublishDate!: Date;
  @Input() public post?: PartnerCampaignPost;
  @Input() public postLog?: PartnerFacebookPostLog;
  @Input() public resumePaymentInfo?: {
    clientSecret: string;
    paymentIntentId: string;
    redirectStatus: string;
  };

  @Output() public goBackEvent = new EventEmitter<void>();
  @Output() public paymentSuccessEvent = new EventEmitter<
    PartnerGoogleAdPostLog | PartnerFacebookPostLog
  >();
  @Output() public paymentErrorEvent =
    new EventEmitter<PromoteAdPaymentErrorEvent>();
  @Output() public storePaymentProcessingDataEvent = new EventEmitter<string>();
  @Output() public cleanPaymentProcessingDataEvent = new EventEmitter<string>();

  @ViewChild(StripeElementsDirective) elements!: StripeElementsDirective;

  protected elementsOptions?: StripeElementsOptionsMode;
  protected managementFee!: number;
  protected netAmount!: number;
  protected paymentSuccess = false;
  protected processingPayment = false;
  protected stripeFormGroup!: UntypedFormGroup;
  protected taxAmount!: number;
  protected totalCost!: number;
  protected showPaymentElement = true;

  constructor(
    private readonly fb: UntypedFormBuilder,
    private readonly languageService: LanguageService,
    private readonly partnerCampaignAdService: PartnerCampaignAdService,
    protected readonly stripeService: StripeService,
  ) {}

  public ngOnInit(): void {
    this.stripeFormGroup = this.fb.group({});
    this.managementFee = +(
      (this.campaign.managementFee * this.budget) /
      100
    ).toFixed(2);
    this.netAmount = this.managementFee + this.budget;
    this.taxAmount = +(
      (this.campaign.adTaxPercentage * this.netAmount) /
      100
    ).toFixed(2);
    this.totalCost = +(this.taxAmount + this.netAmount).toFixed(2);

    this.setElementsOptions();
    if (this.resumePaymentInfo) {
      this.showPaymentElement = false;
      this.resumePayment(
        this.resumePaymentInfo.clientSecret,
        this.resumePaymentInfo.paymentIntentId,
        this.resumePaymentInfo.redirectStatus,
      );
    }
  }

  private setElementsOptions(): void {
    this.elementsOptions = {
      locale: this.languageService.language,
      mode: "payment",
      amount: this.budgetInCents,
      currency: this.campaign.currency.toLowerCase(),
      payment_method_configuration: env.stripePaymentMethodConfiguration,
    };
  }

  protected get budgetInCents(): number {
    return Math.ceil(this.budget * 100);
  }

  protected async processPayment(): Promise<void> {
    this.processingPayment = true;

    const { error: submitError } = await lastValueFrom(this.elements.submit());
    if (submitError) {
      this.handlePaymentFailed(submitError);
      return;
    }

    const clientSecret = await lastValueFrom(
      this.partnerCampaignAdService.getStripePaymentIntent(
        this.campaign.id,
        this.budgetInCents,
      ),
    );

    const paidPostData = this.preparePaidAdData();
    this.storePaymentProcessingDataEvent.emit(clientSecret);

    this.stripeService
      .confirmPayment({
        elements: this.elements._elements,
        clientSecret: clientSecret,
        confirmParams: { return_url: window.location.href },
        redirect: "if_required",
      })
      .subscribe({
        next: (result: any) => {
          if (
            !result.paymentIntent ||
            result.paymentIntent.status !== "succeeded"
          ) {
            this.handlePaymentFailed(result?.error);
            return;
          }

          this.createPaidAdFromPost(
            paidPostData,
            clientSecret,
            result.paymentIntent.id,
          );
        },
        error: (e: unknown) => {
          console.error(e);
          this.handlePaymentFailed(e);
        },
      });
  }

  private preparePaidAdData(): CreatePaidAdData {
    if (this.post) {
      const facebookPost = this.post as PartnerCampaignFacebookPost;
      return this.post.isFacebookPost
        ? new CreatePaidFacebookAdFromPostData(
            facebookPost,
            this.adDuration,
            this.address.id,
            this.scheduledPublishDate,
            this.isDarkPost,
            this.radius,
            this.postToFacebook,
            this.postToInstagram,
          )
        : new CreatePaidGoogleAdFromPostData(
            this.post as PartnerCampaignGoogleAdPost,
            this.adDuration,
            this.address.id,
            this.scheduledPublishDate,
            this.radius,
          );
    } else if (this.postLog) {
      return new CreatePaidFacebookAdFromPostLogData(
        this.postLog,
        this.adDuration,
        this.address.id,
        this.scheduledPublishDate,
        this.isDarkPost,
        this.radius,
        this.postToFacebook,
        this.postToInstagram,
      );
    }

    throw new Error("No post or post log provided");
  }

  private createPaidAdFromPost(
    paidPostData: CreatePaidAdData,
    clientSecret: string,
    chargeId: string,
  ) {
    this.partnerCampaignAdService
      .createPaidAdFromPost(
        this.campaign.id,
        paidPostData,
        clientSecret,
        chargeId,
      )
      .subscribe({
        next: (postLog: PartnerGoogleAdPostLog | PartnerFacebookPostLog) => {
          this.processingPayment = false;
          this.paymentSuccess = true;
          this.paymentSuccessEvent.emit(postLog);
          this.cleanPaymentProcessingDataEvent.emit();
        },
        error: (e) => {
          this.handlePaymentFailed(e);
        },
      });
  }

  public resumePayment(
    clientSecret: string,
    paymentIntentId: string,
    redirectStatus: string,
  ): void {
    this.processingPayment = true;

    if (redirectStatus !== "succeeded") {
      this.handlePaymentFailed(redirectStatus);
      return;
    }

    const paidPostData = this.preparePaidAdData();
    this.createPaidAdFromPost(paidPostData, clientSecret, paymentIntentId);
  }

  private handlePaymentFailed(error: unknown): void {
    console.error(error);
    this.processingPayment = false;
    this.paymentErrorEvent.emit({
      errorMessage: "partner.promoteAd.cardPaymentFailed",
    });
    this.showPaymentElement = true;
    this.cleanPaymentProcessingDataEvent.emit();
  }

  protected goBack(): void {
    this.goBackEvent.emit();
  }
}

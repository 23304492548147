import dayjs from "dayjs";

import { PartnerCampaignGoogleAdPost } from "../../models/partnerCampaignGoogleAdPost";

export class CreatePaidGoogleAdFromPostData {
  public postId: number;
  public adDuration: number;
  public addressId: number;
  public scheduledPublishDate: number;
  public radius: number;
  constructor(
    post: PartnerCampaignGoogleAdPost,
    adDuration: number,
    addressId: number,
    scheduledPublishDate: Date,
    radius: number,
  ) {
    this.postId = post.id;
    this.adDuration = adDuration;
    this.addressId = addressId;
    this.scheduledPublishDate = dayjs(scheduledPublishDate).unix();
    this.radius = radius;
  }
}

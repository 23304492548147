import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { Partner } from "../../../shared/models/partner";
import { PartnerCampaign } from "../../../shared/models/partnerCampaign";
import { PartnerCampaignPost } from "../../../shared/models/partnerCampaignPost";
import { PostLog } from "../../../shared/models/postLog";
import { StorageKeys } from "../../../shared/services/local-storage.service";
import { SessionStorageService } from "../../../shared/services/session-storage.service";
import { PartnerCampaignPromoteAdDialogData } from "../../partner-campaign-promote-ad-dialog/partner-campaign-promote-ad-dialog-data";
import { PartnerCampaignPromoteAdDialogResumeData } from "../../partner-campaign-promote-ad-dialog/partner-campaign-promote-ad-dialog-resume-base-data";
import { PartnerCampaignPromoteAdDialogComponent } from "../../partner-campaign-promote-ad-dialog/partner-campaign-promote-ad-dialog.component";

@Injectable()
export class ResumeAdCreationInteractor {
  public constructor(
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly dialog: MatDialog,
    private readonly sessionStorageService: SessionStorageService,
  ) {}

  public execute(campaign: PartnerCampaign, partner: Partner): void {
    if (
      this.activatedRoute.snapshot.queryParams.payment_intent &&
      this.activatedRoute.snapshot.queryParams.payment_intent_client_secret &&
      this.activatedRoute.snapshot.queryParams.redirect_status
    ) {
      const paymentIntentId =
        this.activatedRoute.snapshot.queryParams.payment_intent;
      const clientSecret =
        this.activatedRoute.snapshot.queryParams.payment_intent_client_secret;
      const redirectStatus =
        this.activatedRoute.snapshot.queryParams.redirect_status;

      const existingPaymentProcessingStore =
        this.sessionStorageService.getParsed<
          PartnerCampaignPromoteAdDialogResumeData[]
        >(StorageKeys.PaymentProcessing);
      const paymentProcessingData = existingPaymentProcessingStore?.find(
        (i) => i.clientSecret === clientSecret,
      );

      const queryParams = { ...this.activatedRoute.snapshot.queryParams };
      delete queryParams.payment_intent;
      delete queryParams.payment_intent_client_secret;
      delete queryParams.redirect_status;

      // Remove returned parameters from history to avoid re-triggering the ad creation
      this.router.navigate([], {
        relativeTo: this.activatedRoute,
        queryParams: queryParams,
        replaceUrl: true,
      });

      if (!paymentProcessingData) {
        console.error("Could not recover payment processing data");
        return;
      }

      let postLog: PostLog | PartnerCampaignPost | undefined;

      postLog = campaign.posts.find(
        (p) => p.id === paymentProcessingData.data.postId,
      );

      if (!postLog) {
        campaign.details.forEach((detail) => {
          detail.categories.forEach((category) => {
            if (!postLog) {
              postLog = category.posts.find(
                (p) => p.id === paymentProcessingData.data.postId,
              );
            }
          });
        });
      }

      if (!postLog) {
        console.error("Could not find post log");
        return;
      }

      paymentProcessingData.paymentIntentId = paymentIntentId;
      paymentProcessingData.redirectStatus = redirectStatus;

      this.dialog.open(PartnerCampaignPromoteAdDialogComponent, {
        data: new PartnerCampaignPromoteAdDialogData(
          campaign,
          postLog,
          paymentProcessingData.data.scheduledPublishDate,
          partner,
          paymentProcessingData.data.redirectToScheduledPosts,
          paymentProcessingData.data.postToFacebook,
          paymentProcessingData.data.postToInstagram,
          paymentProcessingData,
        ),
        disableClose: true,
      });
    }
  }
}
